import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const ROUTES: Routes = [
 
  {
    path: 'maps',
    loadChildren: () => import('../../modules/heatgrid.module/heatgrid.module').then(m => m.HeatGridModule)
  },
  // {
  //   path: 'list',
  //   loadChildren: () => import('../../modules/list-view.module/list.module').then(m => m.ListModule) 
  // },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard-retail.module/dashboard-retail.module').then(m => m.DashboardRetailModule) 
  }
 
 
]; 
