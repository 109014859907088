<!-- Theme customizer Starts-->
<div #customizer class="customizer d-none d-lg-none d-xl-block filter" [ngClass]="{open: isOpen}"
  [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor">
  <a class="customizer-close" (click)="closeFilter()">
    <i class="ft-x font-medium-3"></i></a><a class="customizer-toggle bg-primary" id="customizer-toggle-icon"
    (click)="toggleFilter()">
    <i class="ft-filter font-medium-1 white align-middle"></i>
  </a>
  <div [perfectScrollbar] class="customizer-content p-3 ps-container ps-theme-dark"
    data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
    <h4 class="text-uppercase">{{'FILTERS.TITLE'|translate}}</h4>
    <!-- <p>{{'FILTERS.DESCRIPTION'|translate}} </p> -->
    <!-- Options Starts-->
    <div class="ct-layout" *ngIf="filterService.isOrganizazionLoaded() && filterService.organization.sites && filterService.organization.sites.length > 1">
      <hr>
      <h6 class="mb-4 d-flex align-items-center"><i
          class="ft-user font-medium-2 mr-2"></i><span>{{'FILTERS.RETAIL.SITE'|translate}}</span>
      </h6>
      <button (click)="filterService.resetType(0)" ngbTooltip="Reset" type="button"
        class="bt-reset btn btn-sm btn-outline-primary btn-icon round mr-1 mb-1 d-inline"><i
          class="ft-rotate-ccw"></i></button>
      <div class="layout-switch dark">
        <ng-select [items]="filterService.organization.sites"
           bindLabel="site_name"
           bindValue="site_uuid"
           [(ngModel)]="_siteId">
          </ng-select>
      </div>

    </div>

    <div class="ct-layout" *ngIf="utilityService.canSwitch()">
      <hr>
      <h6 class="mb-4 d-flex align-items-center"><i
          class="ft-user font-medium-2 mr-2"></i><span>{{'FILTERS.RETAIL.GENDER'|translate}}</span>
      </h6>
      <button (click)="filterService.resetType(0)" ngbTooltip="Reset" type="button"
        class="bt-reset btn btn-sm btn-outline-primary btn-icon round mr-1 mb-1 d-inline"><i
          class="ft-rotate-ccw"></i></button>
      <div class="layout-switch">
        <div class="checkbox d-inline-block light-layout mr-3">
          <input id="ll-switch" type="checkbox" name="layout-switch" [checked]="filter.gender.indexOf('m') > -1"
            (change)="filterService.switchGender($event,'m')">
          <label for="ll-switch">{{'FILTERS.RETAIL.MALE'|translate}}</label>
        </div>
        <div class="checkbox d-inline-block dark-layout mr-3">
          <input id="dl-switch" type="checkbox" name="layout-switch" [checked]="filter.gender.indexOf('f') > -1"
            (change)="filterService.switchGender($event,'f')">
          <label for="dl-switch">{{'FILTERS.RETAIL.FEMALE'|translate}}</label>
        </div>
      </div>

    </div>


    <!-- Options Ends-->
    <div class="ct-layout" *ngIf="utilityService.canSwitch()">
      <hr>
      <h6 class="mb-4 d-flex align-items-center"><i
          class="ft-watch font-medium-2 mr-2"></i><span>{{'FILTERS.RETAIL.AGES'|translate}}</span>
      </h6>
      <button (click)="filterService.resetType(1)" ngbTooltip="Reset" type="button"
        class="bt-reset btn btn-sm btn-outline-primary btn-icon round mr-1 mb-1 d-inline"><i
          class="ft-rotate-ccw"></i></button>
      <div class="layout-switch grid-x3">
       
        <div class="checkbox d-inline-block light-layout mr-3" *ngFor="let item of ageTypes | enumToArray">
          <input id="ll-switch{{ageTypes[item]}}" type="checkbox" name="layout-switch{{ageTypes[item]}}"
            [checked]="filter.ages.indexOf(item) > -1"
            (change)="filterService.switchAge($event,item)">
          <label for="ll-switch{{ageTypes[item]}}"> {{ageTypes[item]}}</label>
        </div>

      </div>

    </div>
    <!-- Options Starts-->
    <div class="ct-layout">
      <hr>
      <h6 class="mb-4 d-flex align-items-center"><i
          class="ft-clock font-medium-2 mr-2"></i><span>{{'FILTERS.TIME_SELECTION_TITLE'|translate}}</span>
      </h6>
      <!-- <button (click)="resetFilter(2)" ngbTooltip="Reset" type="button" class="bt-reset btn btn-sm btn-outline-primary btn-icon round mr-1 mb-1 d-inline"><i class="ft-rotate-ccw"></i></button> -->
      <div class="layout-switch">
        <form>
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <div class="input-group round">
                  <input class="form-control round" placeholder="{{'FILTERS.START_DATE'|translate}}" name="dp1"
                    [(ngModel)]="filter.dateFrom" ngbDatepicker [maxDate]="maxDate" #d2="ngbDatepicker">
                  <div class="input-group-append">
                    <div class="input-group-text" (click)="d2.toggle()">
                      <i class="fa fa-calendar" style="cursor: pointer;"></i>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <div class="input-group round">
                  <input class="form-control round" placeholder="{{'FILTERS.END_DATE'|translate}}" name="dp2"
                    [(ngModel)]="filter.dateTo" ngbDatepicker [maxDate]="maxDate" [minDate]="filter.dateFrom"
                    #d3="ngbDatepicker">
                  <div class="input-group-append">
                    <div class="input-group-text" (click)="d3.toggle()">
                      <i class="fa fa-calendar" style="cursor: pointer;"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
    <div class="ct-layout">
      <div class="mb-1">
        <button (click)="apply()" type="button"
          class="btn btn-sm btn-outline-primary btn-icon round mr-1 mb-1 d-inline">{{'FILTERS.LOAD'|translate}}</button>

      </div>
    </div>
    <h6 class="count-label">
      {{filterService.itemsCount}}
    </h6>
  </div>
</div>
<!-- Theme customizer Ends-->