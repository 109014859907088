import { UtilityService } from '@shared/services/utility.service';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from './storage.service';
import { SESSION_FILTER_RETAIL_KEY } from '../constants/app.constants';
import { environment } from '@environments/environment';
import { AgesType, OrgInfoRetriveResponseSites } from '@api-client-retail/index';
import { OrgInfoRetriveResponse } from '@api-client-retail/model/orgInfoRetriveResponse';
import { RolesDashboardType } from '@api-client-retail/model/rolesView';

@Injectable({
  providedIn: "root"
})
export class FilterRetailService implements OnDestroy {

  private _defaultDateFrom = environment.options.defaultFilter.startDate;
  private _defaultDateTo = environment.options.defaultFilter.endDate;
  private _organization: OrgInfoRetriveResponse;
  public get organization(): OrgInfoRetriveResponse {
    return this._organization;
  };
  public set organization(org) {
    this._organization = org;
    if (this.organization.sites && this.organization.sites.length > 0 && !this._siteId)
      this._siteId = this.organization.sites[0].site_uuid;
    this._setEnvRole();
    this.orgLoadedSubject.next(true);
  };
  public _siteId = "";
  public itemsCount: number;
  public templateFilter: ITemplateFilterRetail = this.setFilterValue();
  templateFilterSubject = new BehaviorSubject<ITemplateFilterRetail>(this.templateFilter);
  templateFilter$ = this.templateFilterSubject.asObservable();

  orgLoadedSubject = new BehaviorSubject<boolean>(false);
  orgLoaded$ = this.orgLoadedSubject.asObservable();

  constructor(private localStorageService: LocalStorageService, private utilityService: UtilityService) {

  }
  private _setEnvRole() {
    if (!this.organization) environment.retailRolesView.push(RolesDashboardType.none);
    const roles = Object.values(this.organization.organization.dimensions);
    if (roles.includes("age-gender-gender"))
      environment.retailRolesView.push(RolesDashboardType.poi);
    if (roles.includes("age-gender-observer") && roles.includes("presence"))
      environment.retailRolesView.push(RolesDashboardType.all);
    if (!roles.includes("age-gender-observer") && roles.includes("presence"))
      environment.retailRolesView.push(RolesDashboardType.onlyPresence);
    if (roles.includes("age-gender-observer") && !roles.includes("presence"))
      environment.retailRolesView.push(RolesDashboardType.withoutPresence);
     if (roles.includes("in-out"))
      environment.retailRolesView.push(RolesDashboardType.inOut);
     if (roles.includes("in-out-age-gender"))
      environment.retailRolesView.push(RolesDashboardType.inOutGender);
    if (roles.includes("age-gender-roi"))
      environment.retailRolesView.push(RolesDashboardType.roi);
  }


  ngOnDestroy(): void {

  }
  isOrganizazionLoaded(): boolean {
    return this.organization ? true : false;
  }
  getCurrentSite() {
    if (!this.organization || !this.organization.sites) return '';
    {
      const f = this.organization.sites.find(c => c.site_uuid == this._siteId);
      return f && f.site_name ? f.site_name : "";
    }
  }
  getCurrentSiteObj():OrgInfoRetriveResponseSites {
    if (!this.organization || !this.organization.sites) return {};
    {
      const f = this.organization.sites.find(c => c.site_uuid == this._siteId);
      return f ;
    }
  }
  getSites(): Array<any> {
    return this.organization?.sites || [];
  }

  setFilterValue(): ITemplateFilterRetail {
    const _session = this.localStorageService.getData(SESSION_FILTER_RETAIL_KEY);

    if (_session) {
      this._siteId = _session.site;
      return this.templateFilter = _session;
    }
    else {

      const today = new Date();
      const priorDateTimeStamp = new Date().setDate(today.getDate() - 30);
      const priorDate = new Date(priorDateTimeStamp);

      this._defaultDateTo = { year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate() }
      this._defaultDateFrom = { year: priorDate.getFullYear(), month: (priorDate.getMonth() + 1), day: (priorDate.getDate()) }
      const _agesTypeId = Object.values(AgesType).filter((v) => !isNaN(Number(v)));
      console.log(_agesTypeId);
      return this.templateFilter = {
        gender:  ["m", "f"],
        dateFrom: this._defaultDateFrom,
        dateTo: this._defaultDateTo,
        site: this._siteId,
        ages: _agesTypeId.map(a => a.toString()),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hidden: true
      };
    }
  }
  switchGender(e, type) {
    const index = this.templateFilter.gender.indexOf(type);
    if (index > - 1 && !e.target.checked)
      this.templateFilter.gender.splice(index, 1);
    else {
      if (index == -1) this.templateFilter.gender.push(type);
    }

    //this.applytemplateFilterigChange();
  }
  switchAge(e, type) {
    const index = this.templateFilter.ages.indexOf(type);
    if (index > - 1 && !e.target.checked)
      this.templateFilter.ages.splice(index, 1);
    else {
      if (index == -1) this.templateFilter.ages.push(type);
    }

    // this.applytemplateFilterigChange();
  }
  resetType(t: FilterRetailType) {

    switch (t) {
      case FilterRetailType.gender:
        this.templateFilter.gender = ['m', 'f'];
        break;
      case FilterRetailType.interval:
        this.templateFilter.dateFrom = this._defaultDateFrom;
        this.templateFilter.dateTo = this._defaultDateTo;
        break;
      case FilterRetailType.age:
        this.templateFilter.ages = ['1', '2', '3', '4', '5', '6'];
        break;
      default:
      // code block
    }

    this.templateFilterSubject.next(this.templateFilter);
  }
  applytemplateFilterigChange() {
    this.templateFilter.site = this._siteId;
    this.localStorageService.saveData(SESSION_FILTER_RETAIL_KEY, this.templateFilter);
    this.templateFilterSubject.next(this.templateFilter);
  }
  _removeNegativeValue(d) {
    return !d || d < 0 ? 0 : Math.round(d);
    //return !d || d < 0 ? Math.floor((Math.random() * 1000) + 1) : d;
  }

}
export interface ITemplateFilterRetail {
  site: string;
  dateFrom: any | undefined;
  dateTo: any | undefined;
  gender: Array<string>;
  ages: Array<string>;
  timezone: string;
  hidden: boolean;
}
export enum FilterRetailType {
  gender,
  age,
  interval
}
export enum DashboardViewType {
  gender,
  age,
  presence,
  in,
  out
}
