import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/auth/auth.service';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { BASE_NOMINATIM_URL, DEFAULT_VIEW_BOX } from '../constants/app.constants';
import 'rxjs/add/observable/of';
import domToImage from 'dom-to-image';
import jsPDF, { jsPDFOptions } from 'jspdf';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { RolesDashboardType } from '@api-client-retail/model/rolesView';

@Injectable()
export class UtilityService {
  requestDownloadSubject = new BehaviorSubject<string>(undefined);
  requestDownloadSubject$ = this.requestDownloadSubject.asObservable();

  constructor(private translate: TranslateService,private authService:AuthService,private http: HttpClient) {
  }
  
  requestDownload(format: string){
    this.requestDownloadSubject.next(format);
  }


  downloadPagePdf(element, pdfInfo) {
    const width = element.nativeElement.clientWidth;
    const height = element.nativeElement.clientHeight + 40;
    let imageUnit = 'portrait';
    
    domToImage
      .toPng(element.nativeElement, {
        width: width,
        height: height
      })
      .then(result => {
        const jsPdfOptions:jsPDFOptions = {
          format: [width + 50, height + 220],
          orientation: width > height? 'l' : 'p'
        };
        const pdf = new jsPDF(jsPdfOptions);
         pdf.setFontSize(72);
         pdf.setTextColor('#2585fe');
         pdf.text(this.translate.instant("DOWNLOAD.DASHBOARD_TITLE"), 25, 75);
         pdf.setFontSize(32);
         pdf.setTextColor('#000');
         pdf.text(this.translate.instant("DOWNLOAD.INFO") + pdfInfo, 25, 115);
        pdf.addImage(result, 'PNG', 25, 185, width, height);
        pdf.save(this.translate.instant("DOWNLOAD.NAME") + '.pdf');
      })
      .catch(error => {
      });
  }


  private addressRequest = [];
  addAddressRequest(index){
    this.addressRequest.push({
      index: index,
      ready:false
    });
    if(this.addressRequest.length == 1)
    this.addressRequestSubject.next(this.addressRequest[0]);
  }
  removeAddressRequest(index){
    const el = this.addressRequest.find(C=>C.index == index);
    this.addressRequest.splice(index, 1);
    if(this.addressRequest.length > 1)
    this.addressRequestSubject.next(this.addressRequest[0]);
  }
  addressRequestSubject = new BehaviorSubject<any>(this.addressRequest);
  addressRequestSubject$ = this.addressRequestSubject.asObservable();


  reloadDataSubject = new BehaviorSubject<boolean>(false);
  reloadDataSubject$ = this.reloadDataSubject.asObservable();
  
  getAuthImg(path):string{
    return this.authService.getAuthImgPath(path);
  }
  reloadAllData(){
    this.reloadDataSubject.next(true);
  }

  addressLookup(req?: any): Observable<NominatimResponse[]> {
    let url = `https://${BASE_NOMINATIM_URL}/search?format=json&q=${req}&${DEFAULT_VIEW_BOX}&bounded=1`;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) =>{
            return Observable.of(new NominatimResponse('0','0', req));
        }),
        map((data: any[]) => data.map((item: any) => new NominatimResponse(
          item.lat,
          item.lon,
          item.display_name
          ))
        )
        
      )
      
  }
  getLocation(val:string, inverted?){
    val = val.replace('POINT(','').replace(')','').replace(' ',',');
    return !inverted?
     [
            Number(val.split(',')[0]), 
            Number(val.split(',')[1])
          ]
      :
      [ 
            Number(val.split(',')[1]), 
            Number(val.split(',')[0])
          ];
    }
  formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }
  canSwitch():boolean{
    return environment.retailRolesView.includes(RolesDashboardType.inOutGender) ||
    environment.retailRolesView.includes(RolesDashboardType.all) 
  }

  downloadFromURL(url) {
    const a = document.createElement('a')
    a.href = url
    a.download = url.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
export class NominatimResponseWrap {
  constructor(
    public id: string,
    public response: NominatimResponse,
  ) { }
}
export class NominatimResponse {
  constructor(
    public latitude: string,
    public longitude: string,
    public displayName: string
  ) { }
}