/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-05-23T15:26:19Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface StatsAggregatesByPoiResponseTime { 
    male?: StatsAggregatesByPoiResponseTimeInner;
    female?: StatsAggregatesByPoiResponseTimeInner;
    
}
export interface StatsAggregatesByPoiResponseTimeInner  {
    age1?: number;
    age2?: number;
    age3?: number;
    age4?: number;
    age5?: number;
    age6?: number;
}