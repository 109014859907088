/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-04-05T10:32:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { OrgInfoRetriveResponsePois } from "./orgInfoRetriveResponsePois";


export interface OrgInfoRetriveResponseOrganization { 
    org_name?: string;
    org_logo?: string;
    user_is_admin?: boolean;
    
    dimensions?:Array<string>;
}
