import { RolesDashboardType } from '@api-client-retail/model/rolesView';
import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/dashboard', title: 'Dashboard', icon: 'icon-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/maps', title: 'NAVBAR.MENU_ITEMS.MAP', icon: 'icon-map', class: '', badge: '', badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   roles: [RolesDashboardType.all, RolesDashboardType.onlyPresence, RolesDashboardType.withoutPresence]
  // },
  {
    path: '/dashboard', title: 'NAVBAR.MENU_ITEMS.DASHBOARDS', icon: 'icon-bar-chart', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/dashboard/roi',
        title: 'NAVBAR.SUB_MENU_ITEMS.ROI',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RolesDashboardType.all, RolesDashboardType.roi]
      },
      {
        path: '/dashboard/poi',
        title: 'NAVBAR.SUB_MENU_ITEMS.POI',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RolesDashboardType.all, RolesDashboardType.poi]
      },
      {
        path: '/dashboard/presence',
        title: 'NAVBAR.SUB_MENU_ITEMS.PRESENCE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RolesDashboardType.all, RolesDashboardType.onlyPresence, RolesDashboardType.inOut, RolesDashboardType.inOutGender]
      },
      {
        path: '/dashboard/performance',
        title: 'NAVBAR.SUB_MENU_ITEMS.PERFORMANCE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RolesDashboardType.all, RolesDashboardType.withoutPresence]
      },
      {
        path: '/dashboard/poa',
        title: 'NAVBAR.SUB_MENU_ITEMS.POA',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RolesDashboardType.inOut, RolesDashboardType.inOutGender]
      }]
  },
  {
    path: '/pages/users-list', title: 'NAVBAR.MENU_ITEMS.USERS', icon: 'icon-settings', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    isAdmin: true,
    submenu: []
  }
];
