<nav [attr.data-background-color]="config?.layout.navbar.backgroundColor" class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" >
        <li class="nav-item">
          <div class="logo"><a class="logo-text" [routerLink]="['/maps']" >
              <div class="logo-img"><img class="logo-img" alt="Keye logo" [src]="logoUrl" *ngIf="logoUrl"></div>
            </a></div>
        </li>
        <li class="nav-item">
          <span *ngIf="!orgInfoRetriveResponse" class="badge bg-light-primary ml-4 small">{{organization?.group_name}}</span>
          <span *ngIf="orgInfoRetriveResponse" class="badge bg-light-primary ml-4 small">{{filterRetailService.getCurrentSite()}}</span>
        </li>
       
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item mr-2 d-none d-lg-block">
              <a class="nav-link apptogglefullscreen" [routerLink]="['/maps']" *ngIf="config?.layout.menuPosition === 'Top'"><i class=" {{toggleClass}} font-medium-3"></i></a>
            </li>
           
          </ul> 
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
              (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
          </li>
            <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown *ngIf="config.layout.navbar.showDownload">
              <a class="nav-link apptogglefullscreen dropdown-toggle user-dropdown d-flex align-items-end" id="dropdowndownload"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <i class="ft-download font-medium-3"></i>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0 bg-dark" aria-labelledby="dropdowndownload"
              ngbDropdownMenu>
              <a class="dropdown-item white" (click)="utilityService.requestDownload('PDF')">
                <div class="d-flex align-items-center">
                  <i class="ft-download mr-2"></i><span>{{'HEADER.PDF' | translate}}</span>
                </div>
              </a>
              <a class="dropdown-item white" (click)="utilityService.requestDownload('CSV')">
                <div class="d-flex align-items-center">
                  <i class="ft-download mr-2"></i><span>{{'HEADER.CSV' | translate}}</span>
                </div>
              </a>
            </div>


          </li>

          <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">1</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">1 New
                      Notification</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer">Mark all as read</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
             
             
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-danger bg-lighten-4 mr-3 p-1">
                        <span class="avatar-content font-medium-2"><i class="icon-pointer text-danger"></i></span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>New POI done</span><small class="grey lighten-1 font-italic float-right">3 hrs
                          ago</small>
                      </h6>
                    </div>
                  </div>
                </div>
               
            
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-3">
                  Read All Notifications
                </div>
              </li>
            </ul>
          </li> -->
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{(user | async)?.username}}</span><span
                  class="text-right text-muted font-small-3 text-lowercase">
                 <ng-container> {{(role) || '-'}}</ng-container>
                </span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar.svg" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0 bg-dark" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <a class="dropdown-item white" routerLink="/pages/change-password" >
                <div class="d-flex align-items-center">
                  <i class="icon-key mr-2"></i><span>{{'HEADER.CHANGE_PASSWORD' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item white" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{'HEADER.LOGOUT' | translate}}</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>
