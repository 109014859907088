/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-09-01T10:40:04Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PoaAggregatesWeeklyAndTimeResponseIngressFriday { 
    timegroup?: number;
    female?: number;
    male?: number;
    age_1?: number;
    age_2?: number;
    age_3?: number;
    age_4?: number;
    age_5?: number;
    age_6?: number;
    p_unique?: number;
}