import { filter } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { customAnimations } from "@shared/animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '@shared/services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '@shared/services/layout.service';
import { AuthService } from "@shared/auth/auth.service";
import { Router } from '@angular/router';
import { env } from 'process';
import { DefaultServiceRetail } from '@api-client-retail/index';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  public subMenuItemObj: any = {
    id: undefined,
    items: []
  };
  public showSubMenu: boolean = false;

  level: number = 0;
  public config: any = {};
  protected innerWidth: any;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  isAdmin: boolean;
  menuIconUrl = 'assets/img/menu.svg';
  isLoading:boolean = true;
  constructor(
    private router:Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private dataControllerServiceretail: DefaultServiceRetail
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    //this.isAdmin = authService.isAdmin();
    this.isTouchDevice();
    
    this.getOrganizationRetail();
  }
  private getOrganizationRetail() {
    if (!environment.orgInfoRetriveResponse) {
      this._subscribers.push(this.dataControllerServiceretail.organizationInfoRetriveGet().subscribe(result => {
        environment.orgInfoRetriveResponse = result;
        this.isAdmin = this.authService.isAdmin();
        this.init();
        this.isLoading = false;
      }
      ));
    }
    else {
      this.isAdmin = this.authService.isAdmin();
      this.init();
      this.isLoading = false;
    }

  }
  checkPermission(item):boolean{
     let b = false;
    environment.retailRolesView.forEach(r=>{
      if(item.roles.includes(r))b = true
    })

    return !item.roles || b;
  }
  private init(){
    this._subscribers.push(
      this.router.events.subscribe((val) => {
        this.showSubMenu = false;
        this.menuItems.forEach(m=>{
          m.open = false
        })
      }));

    const s = ROUTES;
    const res = s.filter(c => 
      (!c.isAdmin || (this.isAdmin && c.isAdmin)) &&
      ((!c.roles) || (this.checkPermission(c)))
    );
    this.menuItems = res;
  }
  ngOnInit() {
   
  }

  ngAfterViewInit() {

    this._subscribers.push(this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    }));

    this._subscribers.push( this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }));

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {





    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }
  checkIsSubItemsActive(item):boolean{
    if(this.showSubMenu) return true;
    if(!item.submenu)return false;
    const r = this.router.url;
    const routes = item.submenu.map(c=> c.path);
    return routes.includes(r);
  }
  openSubMenu(item) {
    item.open = !item.open;
    this.showSubMenu = item.open;
    if (item.open)
      {
        this.subMenuItemObj.id = item.path;
        this.subMenuItemObj.items = item.submenu;
      }
    else this.subMenuItemObj = { id: undefined, items: [] };
    this.cdr.detectChanges();
  }
  getchildrenCount(subitems):number{
    const d = subitems.filter(s=>{
      if(this.checkPermission(s)) return s;
    });
    return d.length
  }
  ngOnDestroy() {
    if (this._subscribers) this._subscribers.forEach((s) => s.unsubscribe());

  }

}
