/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-06-01T12:56:35Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GridMapResponsePois { 
    name?: string;
    x?: number;
    y?: number;
}
