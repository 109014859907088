import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";

import { Subscription } from "rxjs";
import { ConfigService } from "@shared/services/config.service";
import { CustomizerService } from "@shared/services/customizer.service";
import { AgesType } from "@api-client-retail/index";
import { FilterRetailService, ITemplateFilterRetail } from "@shared/services/filter-retail.service";
import { UtilityService } from "@shared/services/utility.service";


@Component({
  selector: "app-filters-retail",
  templateUrl:"./filters-retail.component.html",
  styleUrls: ["./filters-retail.component.scss"]
})
export class FiltersRetailComponent implements OnInit, OnDestroy {
 
  @ViewChild("customizer") customizer: ElementRef;
  
  // public someRange: number[] = [0, 3000];
  // dateFrom;
  // dateTo;
  maxDate:any ={};
  date: {year: number, month: number};
  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public filter: ITemplateFilterRetail = this.filterService.templateFilter;
  layoutSub: Subscription;
  public config: any = {};
  bgColor: string;
  ageTypes:any = AgesType;
  _siteId:string;
  constructor(
    public utilityService:UtilityService,
    private customizerService: CustomizerService,
    private configService: ConfigService,
    private renderer: Renderer2,
    public filterService: FilterRetailService,
  ) {
    const today = new Date();   
    this.maxDate = {year:today.getFullYear(), month:(today.getMonth()+1), day: today.getDate() };
    this.isOpen = !this.filter.hidden;
    this.config = this.configService.templateConf;

    
    // console.log('dateFrom',this.filter.dateFrom);
    // console.log('dateTo',this.filter.dateTo);
  }

  @Output() directionEvent = new EventEmitter<Object>();

  canview(){

  }
  ngOnInit() {
    this._siteId = this.filterService._siteId;
    //Set sidebar menu background color
    if (!this.config.layout.sidebar.backgroundColor) {
      this.bgColor = this.customizerService.light_dark_colors[7].code;
    } else {
      this.bgColor = this.config.layout.sidebar.backgroundColor;
    }
    
  }
  
  applyRangeFilter(ev){
      //this.filterService.applytemplateFilterigChange();
  }

  apply(){
    this.filterService._siteId = this._siteId;
    this.filterService.itemsCount = undefined;
    this.filterService.applytemplateFilterigChange()
  }
 
  
  setDate(e?){
    //this.filterService.applytemplateFilterigChange();
  }
  toggleFilter() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeFilter() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }


}
