/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-05-18T15:39:20Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { Empty } from '../model/empty';
import { GenericFilteredRequest } from '../model/genericFilteredRequest';
import { GenericKpiResponse } from '../model/genericKpiResponse';
import { StatsDailyAttendenceResponse } from '../model/statsDailyAttendenceResponse';
import { StatsTimegroupAttendenceResponse } from '../model/statsTimegroupAttendenceResponse';
import { StatsWeeklyAttendanceResponse } from '../model/statsWeeklyAttendanceResponse';

import { BASE_PATH_RETAIL, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { StatsAttendenceByPoiResponse } from '@api-client-retail/model/statsAttendenceByPoiResponse';
import { StatsAggregatesByPoiResponse } from '@api-client-retail/model/statsAggregatesByPoiResponse';
import { KpiBestWeekDaysResponse } from '@api-client-retail/model/kpiBestWeekDaysResponse';
import { KpiBestTargetResponse } from '@api-client-retail/model/kpiBestTargetResponse';
import { KpiBestObservationTimeResponse } from '@api-client-retail/model/kpiBestObservationTimeResponse';
import { StatsUserProfileByPoiResponse } from '@api-client-retail/model/statsUserProfileByPoiResponse';
import { StatsObserversByPoiResponse } from '@api-client-retail/model/statsObserversByPoiResponse';
import { StatsAgeGenderByPoiResponse } from '@api-client-retail/model/statsAgeGenderByPoiResponse';
import { OrgInfoRetriveResponse } from '@api-client-retail/model/orgInfoRetriveResponse';
import { GridMapResponse } from '@api-client-retail/model/gridMapResponse';
import { StatsDailyInOutResponse } from '@api-client-retail/model/statsDailyInOutResponse';
import { KpiIngressEgressTotalInOutResponse } from '@api-client-retail/model/kpiIngressEgressTotalInOutResponse';
import { KpiIngressEgressBestPoaResponse } from '@api-client-retail/model/kpiIngressEgressBestPoaResponse';
import { StatsAggregatesByPoaResponse } from '@api-client-retail/model/statsAggregatesByPoaResponse';
import { AdminListUsersResponse } from '@api-client-retail/model/adminListUsersResponse';
import { AdminDeleteUserRequest } from '@api-client-retail/model/adminDeleteUserRequest';
import { CreateUserRequest } from '@api-client-retail/model/createUserRequest';
import { PoaAggregatesWeeklyAndTimeResponse, StatsTimegroupsInOutResponse, AggregatesWeeklyAndTimegroupResponse } from '..';
import { DataTypeGenericFilteredRequest } from '@api-client-retail/model/dataTypeGenericFilteredRequest';
import { RoiStatsAggregateByDayResponse } from '@api-client-retail/model/roiStatsAggregateByDayResponse';
import { RoiKpiHourlyAvgResponse } from '@api-client-retail/model/roiKpiHourlyAvgResponse';
import { RoiKpiDailyAvgResponse } from '@api-client-retail/model/roiKpiDailyAvgResponse';
import { RoiHourlyByRoiResponse } from '@api-client-retail/model/roiHourlyByRoiResponse';
import { DataTypeRoiFilteredRequest } from '@api-client-retail/model/dataTypeRoiFilteredRequest';
import { RoiDailyByRoiResponse } from '@api-client-retail/model/roiDailyByRoiResponse';


@Injectable()
export class DefaultServiceRetail {

    protected basePath = 'https://jeim29f9yj.execute-api.eu-west-1.amazonaws.com/dev';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH_RETAIL) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserCreateOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUserCreateOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUserCreateOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUserCreateOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/admin/user/create`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserCreatePost(body: CreateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUserCreatePost(body: CreateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUserCreatePost(body: CreateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUserCreatePost(body: CreateUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adminUserCreatePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/admin/user/create`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserDeleteOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUserDeleteOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUserDeleteOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUserDeleteOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/admin/user/delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserDeletePost(body: AdminDeleteUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUserDeletePost(body: AdminDeleteUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUserDeletePost(body: AdminDeleteUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUserDeletePost(body: AdminDeleteUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adminUserDeletePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/admin/user/delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserListGet(observe?: 'body', reportProgress?: boolean): Observable<AdminListUsersResponse>;
    public adminUserListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminListUsersResponse>>;
    public adminUserListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminListUsersResponse>>;
    public adminUserListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AdminListUsersResponse>('get',`${this.basePath}/admin/user/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUserListOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUserListOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUserListOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUserListOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/admin/user/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gridMapOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public gridMapOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public gridMapOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public gridMapOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/grid/map`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gridMapPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GridMapResponse>;
    public gridMapPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GridMapResponse>>;
    public gridMapPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GridMapResponse>>;
    public gridMapPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gridMapPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GridMapResponse>('post',`${this.basePath}/grid/map`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiBestPoaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressKpiBestPoaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressKpiBestPoaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressKpiBestPoaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/kpi/best-poa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiBestPoaPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiIngressEgressBestPoaResponse>;
    public ingressEgressKpiBestPoaPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiIngressEgressBestPoaResponse>>;
    public ingressEgressKpiBestPoaPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiIngressEgressBestPoaResponse>>;
    public ingressEgressKpiBestPoaPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressKpiBestPoaPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiIngressEgressBestPoaResponse>('post',`${this.basePath}/ingress-egress/kpi/best-poa`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiBestWeekDaysOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressKpiBestWeekDaysOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressKpiBestWeekDaysOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressKpiBestWeekDaysOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/kpi/best-week-days`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiBestWeekDaysResponse>;
    public ingressEgressKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiBestWeekDaysResponse>>;
    public ingressEgressKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiBestWeekDaysResponse>>;
    public ingressEgressKpiBestWeekDaysPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressKpiBestWeekDaysPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiBestWeekDaysResponse>('post',`${this.basePath}/ingress-egress/kpi/best-week-days`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiTotalInOutOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressKpiTotalInOutOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressKpiTotalInOutOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressKpiTotalInOutOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/kpi/total-in-out`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressKpiTotalInOutPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiIngressEgressTotalInOutResponse>;
    public ingressEgressKpiTotalInOutPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiIngressEgressTotalInOutResponse>>;
    public ingressEgressKpiTotalInOutPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiIngressEgressTotalInOutResponse>>;
    public ingressEgressKpiTotalInOutPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressKpiTotalInOutPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiIngressEgressTotalInOutResponse>('post',`${this.basePath}/ingress-egress/kpi/total-in-out`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressReportOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressReportOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressReportOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressReportOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/report`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressReportPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressReportPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressReportPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressReportPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressReportPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/ingress-egress/report`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceReportPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceReportPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceReportPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceReportPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceReportPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/presence/report`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsAggregatesByPoaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressStatsAggregatesByPoaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressStatsAggregatesByPoaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressStatsAggregatesByPoaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/stats/aggregates-by-poa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsAggregatesByPoaPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsAggregatesByPoaResponse>;
    public ingressEgressStatsAggregatesByPoaPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsAggregatesByPoaResponse>>;
    public ingressEgressStatsAggregatesByPoaPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsAggregatesByPoaResponse>>;
    public ingressEgressStatsAggregatesByPoaPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressStatsAggregatesByPoaPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsAggregatesByPoaResponse>('post',`${this.basePath}/ingress-egress/stats/aggregates-by-poa`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/stats/aggregates-weekly-and-timegroup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<PoaAggregatesWeeklyAndTimeResponse>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PoaAggregatesWeeklyAndTimeResponse>>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PoaAggregatesWeeklyAndTimeResponse>>;
    public ingressEgressStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressStatsAggregatesWeeklyAndTimegroupPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PoaAggregatesWeeklyAndTimeResponse>('post',`${this.basePath}/ingress-egress/stats/aggregates-weekly-and-timegroup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsDailyTransitOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressStatsDailyTransitOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressStatsDailyTransitOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressStatsDailyTransitOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/stats/daily-transit`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsDailyTransitPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsDailyInOutResponse>;
    public ingressEgressStatsDailyTransitPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsDailyInOutResponse>>;
    public ingressEgressStatsDailyTransitPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsDailyInOutResponse>>;
    public ingressEgressStatsDailyTransitPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressStatsDailyTransitPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsDailyInOutResponse>('post',`${this.basePath}/ingress-egress/stats/daily-transit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsTimegroupsOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public ingressEgressStatsTimegroupsOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public ingressEgressStatsTimegroupsOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public ingressEgressStatsTimegroupsOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/ingress-egress/stats/timegroups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ingressEgressStatsTimegroupsPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsTimegroupsInOutResponse>;
    public ingressEgressStatsTimegroupsPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsTimegroupsInOutResponse>>;
    public ingressEgressStatsTimegroupsPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsTimegroupsInOutResponse>>;
    public ingressEgressStatsTimegroupsPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ingressEgressStatsTimegroupsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsTimegroupsInOutResponse>('post',`${this.basePath}/ingress-egress/stats/timegroups`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationInfoRetriveGet(observe?: 'body', reportProgress?: boolean): Observable<OrgInfoRetriveResponse>;
    public organizationInfoRetriveGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrgInfoRetriveResponse>>;
    public organizationInfoRetriveGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrgInfoRetriveResponse>>;
    public organizationInfoRetriveGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrgInfoRetriveResponse>('get',`${this.basePath}/organization/info/retrive`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationInfoRetriveOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public organizationInfoRetriveOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public organizationInfoRetriveOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public organizationInfoRetriveOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/organization/info/retrive`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsAgeGendersByPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public performanceStatsAgeGendersByPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public performanceStatsAgeGendersByPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public performanceStatsAgeGendersByPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/performance/stats/age-genders-by-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsAgeGendersByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsAgeGenderByPoiResponse>;
    public performanceStatsAgeGendersByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsAgeGenderByPoiResponse>>;
    public performanceStatsAgeGendersByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsAgeGenderByPoiResponse>>;
    public performanceStatsAgeGendersByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling performanceStatsAgeGendersByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsAgeGenderByPoiResponse>('post',`${this.basePath}/performance/stats/age-genders-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsObserversByPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public performanceStatsObserversByPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public performanceStatsObserversByPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public performanceStatsObserversByPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/performance/stats/observers-by-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsObserversByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsObserversByPoiResponse>;
    public performanceStatsObserversByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsObserversByPoiResponse>>;
    public performanceStatsObserversByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsObserversByPoiResponse>>;
    public performanceStatsObserversByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling performanceStatsObserversByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsObserversByPoiResponse>('post',`${this.basePath}/performance/stats/observers-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsUserProfileByPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public performanceStatsUserProfileByPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public performanceStatsUserProfileByPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public performanceStatsUserProfileByPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/performance/stats/user-profile-by-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performanceStatsUserProfileByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsUserProfileByPoiResponse>;
    public performanceStatsUserProfileByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsUserProfileByPoiResponse>>;
    public performanceStatsUserProfileByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsUserProfileByPoiResponse>>;
    public performanceStatsUserProfileByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling performanceStatsUserProfileByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsUserProfileByPoiResponse>('post',`${this.basePath}/performance/stats/user-profile-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestObservationTimeOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiKpiBestObservationTimeOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiKpiBestObservationTimeOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiKpiBestObservationTimeOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/kpi/best-observation-time`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestObservationTimePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiBestObservationTimeResponse>;
    public poiKpiBestObservationTimePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiBestObservationTimeResponse>>;
    public poiKpiBestObservationTimePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiBestObservationTimeResponse>>;
    public poiKpiBestObservationTimePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiKpiBestObservationTimePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiBestObservationTimeResponse>('post',`${this.basePath}/poi/kpi/best-observation-time`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiKpiBestPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiKpiBestPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiKpiBestPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/kpi/best-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericKpiResponse>;
    public poiKpiBestPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericKpiResponse>>;
    public poiKpiBestPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericKpiResponse>>;
    public poiKpiBestPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiKpiBestPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericKpiResponse>('post',`${this.basePath}/poi/kpi/best-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestTargetOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiKpiBestTargetOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiKpiBestTargetOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiKpiBestTargetOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/kpi/best-target`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestTargetPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiBestTargetResponse>;
    public poiKpiBestTargetPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiBestTargetResponse>>;
    public poiKpiBestTargetPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiBestTargetResponse>>;
    public poiKpiBestTargetPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiKpiBestTargetPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiBestTargetResponse>('post',`${this.basePath}/poi/kpi/best-target`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestWeekDaysOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiKpiBestWeekDaysOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiKpiBestWeekDaysOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiKpiBestWeekDaysOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/kpi/best-week-days`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<KpiBestWeekDaysResponse>;
    public poiKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KpiBestWeekDaysResponse>>;
    public poiKpiBestWeekDaysPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KpiBestWeekDaysResponse>>;
    public poiKpiBestWeekDaysPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiKpiBestWeekDaysPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KpiBestWeekDaysResponse>('post',`${this.basePath}/poi/kpi/best-week-days`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiKpiPresenceByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiKpiPresenceByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiKpiPresenceByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiKpiPresenceByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiKpiPresenceByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/poi/kpi/presence-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiStatsAggregatesByPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiStatsAggregatesByPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiStatsAggregatesByPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiStatsAggregatesByPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/stats/aggregates-by-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiStatsAggregatesByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsAggregatesByPoiResponse>;
    public poiStatsAggregatesByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsAggregatesByPoiResponse>>;
    public poiStatsAggregatesByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsAggregatesByPoiResponse>>;
    public poiStatsAggregatesByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiStatsAggregatesByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsAggregatesByPoiResponse>('post',`${this.basePath}/poi/stats/aggregates-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiStatsAttendenceByPoiOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public poiStatsAttendenceByPoiOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public poiStatsAttendenceByPoiOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public poiStatsAttendenceByPoiOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/poi/stats/attendence-by-poi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poiStatsAttendenceByPoiPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsAttendenceByPoiResponse>;
    public poiStatsAttendenceByPoiPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsAttendenceByPoiResponse>>;
    public poiStatsAttendenceByPoiPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsAttendenceByPoiResponse>>;
    public poiStatsAttendenceByPoiPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling poiStatsAttendenceByPoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsAttendenceByPoiResponse>('post',`${this.basePath}/poi/stats/attendence-by-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiAvgTransitTimeOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceKpiAvgTransitTimeOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceKpiAvgTransitTimeOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceKpiAvgTransitTimeOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/kpi/avg-transit-time`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiAvgTransitTimePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericKpiResponse>;
    public presenceKpiAvgTransitTimePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericKpiResponse>>;
    public presenceKpiAvgTransitTimePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericKpiResponse>>;
    public presenceKpiAvgTransitTimePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceKpiAvgTransitTimePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericKpiResponse>('post',`${this.basePath}/presence/kpi/avg-transit-time`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiDailyAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceKpiDailyAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceKpiDailyAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceKpiDailyAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/kpi/daily-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiDailyAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceKpiDailyAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceKpiDailyAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceKpiDailyAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceKpiDailyAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Empty>('post',`${this.basePath}/presence/kpi/daily-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiTotalAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceKpiTotalAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceKpiTotalAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceKpiTotalAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/kpi/total-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiTotalAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericKpiResponse>;
    public presenceKpiTotalAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericKpiResponse>>;
    public presenceKpiTotalAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericKpiResponse>>;
    public presenceKpiTotalAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceKpiTotalAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericKpiResponse>('post',`${this.basePath}/presence/kpi/total-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiWeeklyAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceKpiWeeklyAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceKpiWeeklyAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceKpiWeeklyAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/kpi/weekly-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceKpiWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericKpiResponse>;
    public presenceKpiWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericKpiResponse>>;
    public presenceKpiWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericKpiResponse>>;
    public presenceKpiWeeklyAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceKpiWeeklyAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericKpiResponse>('post',`${this.basePath}/presence/kpi/weekly-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceStatsAggregatesWeeklyAndTimegroupOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceStatsAggregatesWeeklyAndTimegroupOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/stats/aggregates-weekly-and-timegroup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<AggregatesWeeklyAndTimegroupResponse>;
    public presenceStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AggregatesWeeklyAndTimegroupResponse>>;
    public presenceStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AggregatesWeeklyAndTimegroupResponse>>;
    public presenceStatsAggregatesWeeklyAndTimegroupPost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceStatsAggregatesWeeklyAndTimegroupPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AggregatesWeeklyAndTimegroupResponse>('post',`${this.basePath}/presence/stats/aggregates-weekly-and-timegroup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsDailyAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceStatsDailyAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceStatsDailyAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceStatsDailyAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/stats/daily-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsDailyAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsDailyAttendenceResponse>;
    public presenceStatsDailyAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsDailyAttendenceResponse>>;
    public presenceStatsDailyAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsDailyAttendenceResponse>>;
    public presenceStatsDailyAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceStatsDailyAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsDailyAttendenceResponse>('post',`${this.basePath}/presence/stats/daily-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsTimegroupAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceStatsTimegroupAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceStatsTimegroupAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceStatsTimegroupAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/stats/timegroup-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsTimegroupAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsTimegroupAttendenceResponse>;
    public presenceStatsTimegroupAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsTimegroupAttendenceResponse>>;
    public presenceStatsTimegroupAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsTimegroupAttendenceResponse>>;
    public presenceStatsTimegroupAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceStatsTimegroupAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsTimegroupAttendenceResponse>('post',`${this.basePath}/presence/stats/timegroup-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsWeeklyAttendenceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public presenceStatsWeeklyAttendenceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public presenceStatsWeeklyAttendenceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public presenceStatsWeeklyAttendenceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Empty>('options',`${this.basePath}/presence/stats/weekly-attendence`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public presenceStatsWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<StatsWeeklyAttendanceResponse>;
    public presenceStatsWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatsWeeklyAttendanceResponse>>;
    public presenceStatsWeeklyAttendencePost(body: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatsWeeklyAttendanceResponse>>;
    public presenceStatsWeeklyAttendencePost(body: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling presenceStatsWeeklyAttendencePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StatsWeeklyAttendanceResponse>('post',`${this.basePath}/presence/stats/weekly-attendence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public roiKpiDailyAverageOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
     public roiKpiDailyAverageOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
     public roiKpiDailyAverageOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
     public roiKpiDailyAverageOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.options<Empty>(`${this.basePath}/roi/kpi/daily-average`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param dataTypeGenericFilteredRequest 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public roiKpiDailyAveragePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<RoiKpiDailyAvgResponse>;
     public roiKpiDailyAveragePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoiKpiDailyAvgResponse>>;
     public roiKpiDailyAveragePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoiKpiDailyAvgResponse>>;
     public roiKpiDailyAveragePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (dataTypeGenericFilteredRequest === null || dataTypeGenericFilteredRequest === undefined) {
             throw new Error('Required parameter dataTypeGenericFilteredRequest was null or undefined when calling roiKpiDailyAveragePost.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.post<RoiKpiDailyAvgResponse>(`${this.basePath}/roi/kpi/daily-average`,
             dataTypeGenericFilteredRequest,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public roiKpiHourlyAvgPresenceTimeOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
     public roiKpiHourlyAvgPresenceTimeOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
     public roiKpiHourlyAvgPresenceTimeOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
     public roiKpiHourlyAvgPresenceTimeOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.options<Empty>(`${this.basePath}/roi/kpi/hourly-avg-presence-time`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public roiKpiHourlyAvgPresenceTimePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest,observe?: 'body', reportProgress?: boolean): Observable<RoiKpiHourlyAvgResponse>;
     public roiKpiHourlyAvgPresenceTimePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest,observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoiKpiHourlyAvgResponse>>;
     public roiKpiHourlyAvgPresenceTimePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest,observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoiKpiHourlyAvgResponse>>;
     public roiKpiHourlyAvgPresenceTimePost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest,observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         if (dataTypeGenericFilteredRequest === null || dataTypeGenericFilteredRequest === undefined) {
            throw new Error('Required parameter dataTypeGenericFilteredRequest was null or undefined when calling roiKpiDailyAveragePost.');
        }
         // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.post<RoiKpiHourlyAvgResponse>(`${this.basePath}/roi/kpi/hourly-avg-presence-time`,
            dataTypeGenericFilteredRequest,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public roiStatsAggregateByDayOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
     public roiStatsAggregateByDayOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
     public roiStatsAggregateByDayOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
     public roiStatsAggregateByDayOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.options<Empty>(`${this.basePath}/roi/stats/aggregate_by_day`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param dataTypeGenericFilteredRequest 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public roiStatsAggregateByDayPost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<RoiStatsAggregateByDayResponse>;
     public roiStatsAggregateByDayPost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoiStatsAggregateByDayResponse>>;
     public roiStatsAggregateByDayPost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoiStatsAggregateByDayResponse>>;
     public roiStatsAggregateByDayPost(dataTypeGenericFilteredRequest: DataTypeGenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (dataTypeGenericFilteredRequest === null || dataTypeGenericFilteredRequest === undefined) {
             throw new Error('Required parameter dataTypeGenericFilteredRequest was null or undefined when calling roiStatsAggregateByDayPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.post<RoiStatsAggregateByDayResponse>(`${this.basePath}/roi/stats/aggregate_by_day`,
             dataTypeGenericFilteredRequest,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

      /**
     * 
     * 
     * @param dataTypeRoiFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public roiStatsDailyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<RoiDailyByRoiResponse>;
    public roiStatsDailyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoiDailyByRoiResponse>>;
    public roiStatsDailyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoiDailyByRoiResponse>>;
    public roiStatsDailyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dataTypeRoiFilteredRequest === null || dataTypeRoiFilteredRequest === undefined) {
            throw new Error('Required parameter dataTypeRoiFilteredRequest was null or undefined when calling roiStatsDailyByRoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RoiDailyByRoiResponse>(`${this.basePath}/roi/stats/daily-by-roi`,
            dataTypeRoiFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * 
     * 
     * @param dataTypeRoiFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public roiStatsDailyHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<AggregatesWeeklyAndTimegroupResponse>;
     public roiStatsDailyHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AggregatesWeeklyAndTimegroupResponse>>;
     public roiStatsDailyHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AggregatesWeeklyAndTimegroupResponse>>;
     public roiStatsDailyHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (dataTypeRoiFilteredRequest === null || dataTypeRoiFilteredRequest === undefined) {
             throw new Error('Required parameter dataTypeRoiFilteredRequest was null or undefined when calling roiStatsDailyHourlyByRoiPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.post<AggregatesWeeklyAndTimegroupResponse>(`${this.basePath}/roi/stats/daily-hourly-by-roi`,
             dataTypeRoiFilteredRequest,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

      /**
     * 
     * 
     * @param dataTypeRoiFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public roiStatsHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<RoiHourlyByRoiResponse>;
    public roiStatsHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoiHourlyByRoiResponse>>;
    public roiStatsHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoiHourlyByRoiResponse>>;
    public roiStatsHourlyByRoiPost(dataTypeRoiFilteredRequest: DataTypeRoiFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dataTypeRoiFilteredRequest === null || dataTypeRoiFilteredRequest === undefined) {
            throw new Error('Required parameter dataTypeRoiFilteredRequest was null or undefined when calling roiStatsHourlyByRoiPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-RETAIL-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RoiHourlyByRoiResponse>(`${this.basePath}/roi/stats/hourly-by-roi`,
            dataTypeRoiFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

   
 
}
