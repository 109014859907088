/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-07-18T09:56:36Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface KpiIngressEgressBestPoaResponse { 
    value?: string;
    ingress?: number;
    egress?: number;
}
