/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-05-18T15:39:20Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GenericFilteredRequest { 
    site?: string;
    ages?: Array<string>;
    genders?: Array<string>;
    start_time?: number;
    end_time?: number;
    timezone?: string;
}
export enum AgesType {
    '0-15' = 1,
    '16-20' = 2,
    '21-30' = 3,
    '31-45' = 4,
    '46-60' = 5,
    '60+' = 6
  }

 