/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-05-26T15:08:51Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface StatsUserProfileByPoiResponseInner { 
    poi: string;
    gender: StatsUserProfileByPoiResponseInner.GenderEnum;
    age: string;
    percentage: number;
}
export namespace StatsUserProfileByPoiResponseInner {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}
