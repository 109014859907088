import { OrgInfoRetriveResponse } from "@api-client-retail/index";
import { RolesDashboardType } from "@api-client-retail/model/rolesView";


declare var window: any;

export class DynamicEnvironment {
    public retailRolesView:Array<RolesDashboardType> = [];
    private _orgInfoRetriveResponse?:OrgInfoRetriveResponse;
    public get orgInfoRetriveResponse():OrgInfoRetriveResponse{
      return this._orgInfoRetriveResponse;
    }
    public set orgInfoRetriveResponse(val:OrgInfoRetriveResponse){
      this._orgInfoRetriveResponse = val;
    }
    public isAdmin(){
      return  this.orgInfoRetriveResponse?.organization?.user_is_admin || false;
    }
    constructor() {
    }
    
    public get environment() {
        return window.config.environment;
    }
    public get cognitoConfig() {
      return window.config.cognito;
    }
    public get apiConfig(){
      return window.config.api;
    }
    public get options(){
      return window.config.options;
    }
    public get defaultMapCenter(){
      return window.config.defaultMapCenter;
    }
}