/**
 * DEV-KEYE-RETAIL-API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2023-05-26T15:08:51Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface StatsAgeGenderByPoiResponseInner { 
    poi?: string;
    m_age_1?: number;
    m_age_2?: number;
    m_age_3?: number;
    m_age_4?: number;
    m_age_5?: number;
    m_age_6?: number;
    f_age_1?: number;
    f_age_2?: number;
    f_age_3?: number;
    f_age_4?: number;
    f_age_5?: number;
    f_age_6?: number;
}
